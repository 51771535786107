import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import CountryFilter from "./country_filter"

import "./incidents_bar.scss"
import Loading from "../common/loading"

const HolidaySidebar = ({holidays, closeCallback, setDaysAhead, daysAhead, selectCountryCallback}) => {
  const onDaysAheadChange = (e)=>{
    setDaysAhead(parseInt(e.target.value)); 
  }

  const onCountryFilterChange = (e) => {
    if (e.target.value == ""){
      selectCountryCallback([])
    }else{
      selectCountryCallback([e.target.value])
    }
  }

  const sortMenu = () =>{
    return(
      <>
        <div key="1" className="col-sm-12 col-md-6">
				  <CountryFilter changeCallback={onCountryFilterChange}/>
			  </div>
        <div key="2" className="col-sm-12 col-md-6">
          <div className="order-by">
            <label>Order by</label>
            <select name="order-by" onChange={onDaysAheadChange} value={daysAhead}>
              <option key="0" value="30">Current month</option>
              <option key="1" value="14">2 weeks ahead</option>
              <option key="2" value="7">Within a week</option>
            </select>
          </div>
        </div>
      </>
    )
  }

  const isToday = (date) => {
    const today = new Date()
    const someDate = new Date(date);
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
  }

  const getStatus = (holiday) => {
    return isToday(holiday.start_date) ? "Current" : "Prediction"
  }

  const getHolidayList = () =>{
    if(holidays.length === 0){
      return (<Loading/>)
    }
    return holidays.map((holiday, index) => {
      return (
      <div key={index} className="incident">
        <header className="with-icon">
          <i className="incident-icon holiday medium"></i>
          <h5><strong>{holiday.name}</strong> in {countriesTitle(holiday)}</h5>
          <span className="current-label">{getStatus(holiday)}</span>
        </header>
        <div className="properties row">
          <div className="col-md-6 col-xs-12">
            <div className="the-date">{formatDate(holiday)}</div> 
            <label>Start date</label>
          </div>
          <div className="col-md-6 col-xs-12">
            <div className="delay the-date">{holiday.daysDelay} d</div>
            <label>Delay</label>
          </div>
        </div>
        <div className="context">
          <p>{holiday.name} in {countriesToText(holiday)}: On {formatDate(holiday)}</p>
        </div>
      </div>)
    })
  }

  const formatDate = (holiday) => {
    let holiday_day = new Date(holiday.start_date)
    return (holiday_day.getFullYear())+"-"+(holiday_day.getMonth()+1)+"-"+holiday_day.getDate()
  }

  const countriesToText = (holiday) =>{
    let text = "";
    holiday.countries.forEach(country => {
      text += country.name+", "
    });
    text = text.slice(0, -2);

    return text;
  }

  const countriesTitle = (holiday) => {
    if (holiday.countries.length == 1){
      return holiday.countries[0].name
    }

    return holiday.countries.length + " countries"
  }

  const getHeader = () => {
    let text = "Public Holidays ";
    if(daysAhead == 30){
      text += "(A Month Ahead)"
    }else if(daysAhead == 14){
      text += "(2 weeks Ahead)"
    }else if(daysAhead == 7){
      text += "(Within a week)"
    }
    return text
  }

  return (
	 	<div className="incident-list">
			<h4>{getHeader()}</h4>
			<a href="#close" className={"close-button"} onClick={closeCallback}>x</a>
			<div className="row filters">
				{sortMenu()}
			</div>
			{getHolidayList()}
	 	</div>
  )
}

HolidaySidebar.propTypes = {
  holidays: PropTypes.array.isRequired
}
export default HolidaySidebar