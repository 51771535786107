import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Map from "../components/map/map"
import { getHolidays } from "../services/api"
import NotificationsBar from "../components/notifications/notifications_bar"
import Filters from "../components/map/filters"
import Countries from "../components/map/countries"
import HolidaySidebar from "../components/incidents/holiday_sidebar"
import useFilters from "../components/common/filters"

const PublicHolidayMap = () => {
	const [showBar, setShowBar] = React.useState(true)
	const [showNotificationsBar, setShowNotificationsBar] = React.useState(false)
  const [showNotificationSubbar, setShowNotificationSubbar] = React.useState(false)

  // for notifications
  const [filters, countriesSelected, filterController, countryController] = useFilters();
  const [holidays, setHolidays] = React.useState([])
  const [daysAhead, setDaysAhead] = React.useState(30)

	let sidebar = []

  React.useEffect(()=>{
    let _filters = filters;
    _filters.days = parseInt(daysAhead);//how many days ahead to look for
    filterController.setFilters(_filters);
    getHolidays(_filters).then(holidays => {
      setHolidays(holidays)
		})
  }, [daysAhead])

  // load incidents, also used in callback
  const loadIncidents = (_filters) => {
    if(typeof(_filters) != 'undefined' && _filters != null) {
      filterController.setFilters(_filters)
    }
    _filters.days = daysAhead;//how many days ahead to look for
    getHolidays(_filters).then(holidays => {
      setHolidays(holidays)
		})
  }

  const holidaysToIncidents = (holidays) =>{
    let incidents = [];
    for(let i = 0; i < holidays.length; i ++){
      for(let j = 0; j < holidays[i].countries.length; j++){
        let incident = {
          ID: holidays[i].id,
          IncidentType: holidays[i].name,
          Country: holidays[i].countries[j].name,
          CountryCode: holidays[i].countries[j].iso,
          Impact: "medium",
          StartDate: holidays[i].start_date,
          ExpectedDelayText: holidays[i].daysDelay+" Days",
          LatLng: holidays[i].countries[j].LatLng,
          ReportingDate: holidays[i].start_date,
          Description: holidays[i].name+": On "+formatDate(holidays[i])+" in "+ holidays[i].countries[j].name,
          Region: "",
          Status: getStatus(holidays[i]),
          Zipcodes: "",
          Source: "",
          DataSourceUrl: "",
          Pinned: "",
          City: "",
        }
        incidents.push(incident);
      }
    }
    return incidents;
  }

  const getStatus = (holiday) => {
    return holiday.start_date == new Date() ? "current" : "prediction"
  }

  const formatDate = (holiday) => {
    let holiday_day = new Date(holiday.start_date)
    return (holiday_day.getFullYear())+"-"+(holiday_day.getMonth()+1)+"-"+holiday_day.getDate()
  }


	if(showBar) {
		sidebar = (<aside className="alert-sidebar col-md-4 col-sm-12 floating">
      <HolidaySidebar closeCallback={(e) => {setShowBar(false)}}
        holidays={holidays}
        setDaysAhead={setDaysAhead}
        daysAhead={daysAhead}
        selectCountryCallback={countryController.setCountries}/>
    </aside>)
	} else if(showNotificationsBar) {
    let subbar = (<> </>);
    if(showNotificationSubbar) {
      subbar = (
        <aside className="filter-menu alert-sidebar notifications subbar col-md-4 col-sm-12 floating">
          <Countries countryController={countryController} closeCallback={(e) =>{setShowNotificationSubbar(false)}} countries={countriesSelected} key="1"/> 
          <Filters
            setFilterCallback={ filterController.toggleFilter }
            filters={filters}
            closeCallback={ (e) => { setShowNotificationSubbar(false) } }
            key="2"/>
        </aside>
      )
    }
    sidebar = (
      <div>
        <aside className="alert-sidebar notifications col-md-4 col-sm-12 floating">
          <NotificationsBar countries={countriesSelected} filters={filters} closeCallback={(e) => {setShowNotificationsBar(false)}} showSubBarCallback={() => { setShowNotificationSubbar(true) }}/>
        </aside>
        {subbar}
      </div>
    )
  }

	return (
  <Layout>
    <Seo title="PostNL Early Warning System Map" />
    <div className="the-map">
      <Map loadIncidentsCallback={loadIncidents} 
      incidentsCallback={setShowBar} 
      notificationsCallback={setShowNotificationsBar} 
      filterController={filterController} 
      countryController={countryController} 
      filterCallback={filterController.toggleFilter} 
      incidents={holidaysToIncidents(holidays)} 
      countries={countriesSelected} 
      filters={filters} 
      page={"holiday_page"} />  
    </div>
    {sidebar}
  </Layout>
	)
}

export default PublicHolidayMap
